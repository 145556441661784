import React from "react";
import {graphql} from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import PageWrapper from "../../components/PageWrapper";
import { Section, Title, Text, Box, Button } from "../../components/Core";
import PostDetails from "../../sections/blog/PostDetails";
import { navigate } from "gatsby";
import {getProperty} from "../../utils/helperFn";
import moment from "moment";
import Seo from "./../../components/Seo";

const BlogDetails = (props) => {

    const article = getProperty(props.data, 'strapiNewsArticles')

    return (
        <>
            <Seo page={article.slug}/>
            <PageWrapper footerDark>
                <Section className="pb-0">
                    <div className="pt-5"/>
                    <Container>
                        <Row className="justify-content-center text-center">
                            <Col lg="12">
                                <Title variant="news">
                                    {getProperty(article, 'title')}
                                </Title>
                                <Box className="d-flex justify-content-center">
                                    <Text mr={3}>
                                        {moment(article.backdate).format('ll')}
                                    </Text>
                                    {/*<Text mr={3}>
                                        <Link to="/">Technology</Link>
                                    </Text>
                                    <Text>
                                        <Link to="/">David Jones</Link>
                                    </Text>*/}
                                </Box>
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <Section className="pb-5">
                    <Container>
                        <Row>
                            <Col sm="12" md="10" className="mb-5 mx-auto post-details">
                                <PostDetails content={article.content} image={(article.image_content && article.image_content?.childImageSharp) ? article.image_content.childImageSharp.fluid : null}/>
                            </Col>
                            <Col sm="12" md="10" className="mb-5 mx-auto text-center">
                                <Button onClick={() => navigate('/news')}>
                                    Back to News
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </Section>
                {/*<Comments />*/}
            </PageWrapper>
        </>
    );
};

export const data = graphql`
    query getNewsArticleOnSlug($slug: String) {
      strapiNewsArticles(slug: {eq: $slug}) {
        id
        backdate
        content
        title
        slug
        image_content {
           childImageSharp {
             fluid(maxWidth: 1000) {
               ...GatsbyImageSharpFluid
             }
           }
        }
        image_featured {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
         }
      }
    }`


export default BlogDetails;
